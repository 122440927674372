@import "../breakpoints.scss";
.slider {
  width: 100%;
  max-width: 900px;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 5px #fff,
  0 0 5px #fff;
  @include medium {
    max-width: calc(900px * 0.75);
    height: calc(400px * 0.75);
  }
  @include medium-small {
    max-width: calc(900px * 0.60);
    height: calc(400px * 0.60);
  }
  @include small {
    max-width: calc(900px * 0.40);
    height: calc(400px * 0.40);
  }
  @include extra-small {
    max-width: calc(900px * 0.30);
    height: calc(400px * 0.30);
  }
}

.slide {
  width: 100%;
  max-width: 900px;
  height: 400px;
  position: absolute;
  transition: all 0.5s;
  @include medium {
    max-width: calc(900px * 0.75); 
    height: calc(400px * 0.75);
  }
  @include medium-small {
    max-width: calc(900px * 0.60);
    height: calc(400px * 0.60);
  }
  @include small {
    max-width: calc(900px * 0.45);
    height: calc(400px * 0.45);
  }
  @include extra-small {
    max-width: calc(900px * 0.30);
    height: calc(400px * 0.30);
  }
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-arrow-left {
  position: absolute;
  z-index: 10px;
  cursor: pointer;
  top: 45%;
  left: 5%;
  width: 37.9765px;
  height: 37.9765px;
  border-bottom: 15px solid #1b1b1b;
  border-right: 15px solid #1b1b1b;
  transform: rotate(135deg);
  margin: -10px;
  filter: drop-shadow(0 0 5px gray) drop-shadow(0 0 5px gray);

  @include medium-small {
    width: 25.186px;
    height: 25.186px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -8px;
  }
  @include small {
    width: 18.9882px;
    height: 18.9882px;
    border-bottom: 7.5px solid #1b1b1b;
    border-right: 7.5px solid #1b1b1b;
    margin: -8px;
  }
  @include extra-small {
    width: 12.6589px;
    height: 12.6589px;
    border-bottom: 7.5px solid #1b1b1b;
    border-right: 7.5px solid #1b1b1b;
    margin: -8px;
  }
}

.slider-arrow-left:hover {
  width: 44.1859px;
  height: 44.1859px;
  border-bottom: 17.5px solid #1b1b1b;
  border-right: 17.5px solid #1b1b1b;
  margin: -12px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #33ffff) drop-shadow(0 0 10px #66ffff);
  @include medium-small {
    width: 31.4932px;
    height: 31.4932px;
    border-bottom: 12.5px solid #1b1b1b;
    border-right: 12.5px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include small {
    width: 25.186px;
    height: 25.186px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include extra-small {
    width: 18.9882px;
    height: 18.9882px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
}
.slider-arrow-right {
  position: absolute;
  z-index: 10px;
  cursor: pointer;
  top: 45%;
  right: 5%;
  width: 37.9765px;
  height: 37.9765px;
  border-bottom: 15px solid #1b1b1b;
  border-right: 15px solid #1b1b1b;
  transform: rotate(-45deg);
  margin: -10px;
  filter: drop-shadow(0 0 5px gray) drop-shadow(0 0 5px gray);

  @include medium-small {
    width: 25.186px;
    height: 25.186px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -8px;
  }
  @include small {
    width: 18.9882px;
    height: 18.9882px;
    border-bottom: 7.5px solid #1b1b1b;
    border-right: 7.5px solid #1b1b1b;
    margin: -8px;
  }
  @include extra-small {
    width: 12.6589px;
    height: 12.6589px;
    border-bottom: 7.5px solid #1b1b1b;
    border-right: 7.5px solid #1b1b1b;
    margin: -8px;
  }
}

.slider-arrow-right:hover {
  width: 44.1859px;
  height: 44.1859px;
  border-bottom: 17.5px solid #1b1b1b;
  border-right: 17.5px solid #1b1b1b;
  margin: -12px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #33ffff) drop-shadow(0 0 10px #66ffff);
  @include medium-small {
    width: 31.4932px;
    height: 31.4932px;
    border-bottom: 12.5px solid #1b1b1b;
    border-right: 12.5px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include small {
    width: 25.186px;
    height: 25.186px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include extra-small {
    width: 18.9882px;
    height: 18.9882px;
    border-bottom: 10px solid #1b1b1b;
    border-right: 10px solid #1b1b1b;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
}
