@import "../breakpoints.scss";

.pdf-container {
    display: flex;
    justify-content: center;
    user-select: none;
    max-width: 100%;
    overflow-x: auto;
    padding: 0 10px;
}

.controls {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    &__disabled {
        background-color: #145b78;
        opacity: 60%;
        color: white;
    }
    &__active {
        background-color: #1b7ca4;
        color: white;
    }
    &__prev {
        margin-right: 10px;
    }
    &__next {
        margin-left: 10px;
    }
    &__general {
        border: 1px solid cyan;
        font-size: 16px;
        padding: 5px 10px;
        &:hover {
            border: 2px solid cyan;
            box-shadow: 0 0 5px #33ffff,
            0 0 5px #66ffff;
            font-size: 20px;
            color: cyan;
            cursor: pointer;
        }
    }
}

@media print {
    .pdf-container {
      display: none;
    }
}

@media screen and (max-width: $medium) {
    .pdf-container {
        padding: 0;
    }
}

.react-pdf__Page__textContent {
    display: none;
}
.react-pdf__Page__annotations {
    display: none;
}

@media screen and (min-width: #{$medium + 1}) and (max-width: #{$medium-large}) {
    .react-pdf__Page__canvas {
        width: 900px !important;
        height: auto !important;
    }
}

@media screen and (min-width: #{$medium-small + 1}) and (max-width: #{$medium}) {
    .react-pdf__Page__canvas {
        width: 700px !important;
        height: auto !important;
    }
}

@media screen and (min-width: #{$small + 1}) and (max-width: #{$medium-small}) {
    .react-pdf__Page__canvas {
        width: 500px !important;
        height: auto !important;
    }
}

@media screen and (min-width: #{$extra-small + 1}) and (max-width: #{$small}) {
    .react-pdf__Page__canvas {
        width: 400px !important;
        height: auto !important;
    }
}