@import '../../components/utils/breakpoints.scss';

$cream: #eee;
$blue: darken(dodgerblue, 15%);
$button: darken(skyblue, 45%);

.test-option-title {
  display: flex;
  font-weight: 600;
  line-height: 70px;
  font-size: 100px;
  text-align: center;
  font-size: 5rem;
  letter-spacing: 0.3rem;
  color: transparent;
  background-position: 0 0;
  animation: animated-text 20s linear infinite;
  -webkit-text-stroke: 1px rgba(16, 78, 104, 0.8);
  background-image: url('../../sources/title-culor.png');
  background-clip: text;

  @include medium {
    line-height: 60px;
    font-size: 50px;
  }
  @include medium-small {
    line-height: 40px;
    font-size: 45px;
  }
  @include small {
    line-height: 40px;
    font-size: 40px;
  }
  @include extra-small {
    font-weight: 500;
    line-height: 40px;
    font-size: 35px;
  }
  @keyframes animated-text {
    100% {
      background-position: 200% 0;
    }
  }
}
.test-landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.arrow {
  display: flex;
  position: relative;
  top: 80px;
  margin-top: 120px;
  margin-left: 34.55625px;
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 69.1125px;
  height: 69.1125px;
  border-bottom: 15px solid white;
  border-right: 15px solid white;
  transform: rotate(-45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(1) {
  animation-delay: -0.4s;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(-30px, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(-45deg) translate(-30px, 0);
  }
}
.content-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}
.slider-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.first-row {
  display: flex;
  gap: 10px;
}
.second-row {
  display: flex;
  gap: 10px;
}
.show-slider-element-right {
  animation: rightArrowAnimationR 1s ease-in-out,
    rightArrowAnimationL 1s ease-in-out;
}
.show-slider-element-left {
  animation: leftArrowAnimationL 1s ease-in-out,
    leftArrowAnimationR 1s ease-in-out;
}
.hide-slider-element {
  opacity: 0;
}
@keyframes rightArrowAnimationL {
  from {
    opacity: 0;
    transform: translateX(0%);
  }
  to {
    opacity: 1;
    transform: translateX(-100);
  }
}

@keyframes rightArrowAnimationR {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes leftArrowAnimationL {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes leftArrowAnimationR {
  from {
    opacity: 0;
    transform: translateX(0%);
  }
  to {
    opacity: 1;
    transform: translateX(-100);
  }
}
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  background-color: #2a475e;
  border: 2px solid white;
  margin: 10px;
  color: white;
  font-size: 21px;
  padding: 5px;
  border-radius: 20%;
  @include medium {
    font-size: 18px;
    width: 200px;
    height: 200px;
  }
  @include medium-small {
    font-size: 18px;
    width: 150px;
    height: 150px;
  }
  @include small {
    margin: 2px;
    font-size: 16px;
    width: 125px;
    height: 125px;
  }
  @include extra-small {
    margin: 2px;

    font-size: 15px;
    width: 120px;
    height: 120px;
  }
}
.square:hover {
  border: 2px solid cyan;
  box-shadow: 0 0 5px #33ffff, 0 0 5px #66ffff;
  font-size: 23px;
  color: cyan;
  cursor: pointer;
}

.arrow-left {
  display: block;
  width: 75.9531px;
  height: 75.9531px;
  border-bottom: 30px solid white;
  border-right: 30px solid white;
  transform: rotate(135deg);
  margin: -10px;

  @include medium-small {
    width: 50.372px;
    height: 50.372px;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -8px;
  }
  @include small {
    width: 37.9765px;
    height: 37.9765px;
    border-bottom: 15px solid white;
    border-right: 15px solid white;
    margin: -8px;
  }
  @include extra-small {
    width: 25.3177px;
    height: 25.3177px;
    border-bottom: 15px solid white;
    border-right: 15px solid white;
    margin: -8px;
  }
}
.arrow-left:hover {
  width: 88.3718px;
  height: 88.3718px;
  border-bottom: 35px solid white;
  border-right: 35px solid white;
  margin: -12px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #33ffff) drop-shadow(0 0 10px #66ffff);
  @include medium-small {
    width: 62.9865px;
    height: 62.9865px;
    border-bottom: 25px solid white;
    border-right: 25px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include small {
    width: 50.372px;
    height: 6vh;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include extra-small {
    width: 37.9765px;
    height: 37.9765px;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
}
.arrow-right {
  display: block;
  width: 75.9531px;
  height: 75.9531px;
  border-bottom: 30px solid white;
  border-right: 30px solid white;
  transform: rotate(-45deg);
  margin: -10px;

  @include medium-small {
    width: 50.372px;
    height: 50.372px;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -8px;
  }
  @include small {
    width: 37.9765px;
    height: 37.9765px;
    border-bottom: 15px solid white;
    border-right: 15px solid white;
    margin: -8px;
  }
  @include extra-small {
    width: 25.3177px;
    height: 25.3177px;
    border-bottom: 15px solid white;
    border-right: 15px solid white;
    margin: -8px;
  }
}
.arrow-right:hover {
  width: 88.3718px;
  height: 88.3718px;
  border-bottom: 35px solid white;
  border-right: 35px solid white;
  margin: -12px;
  cursor: pointer;
  filter: drop-shadow(0 0 10px #33ffff) drop-shadow(0 0 10px #66ffff);
  @include medium-small {
    width: 62.9865px;
    height: 62.9865px;
    border-bottom: 25px solid white;
    border-right: 25px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include small {
    width: 50.372px;
    height: 50.372px;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
  @include extra-small {
    width: 37.9765px;
    height: 37.9765px;
    border-bottom: 20px solid white;
    border-right: 20px solid white;
    margin: -9px;
    filter: drop-shadow(0 0 5px #33ffff) drop-shadow(0 0 5px #66ffff);
  }
}

.pop-up-container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: fixed;
  padding: 15px;
  max-width: 400px;
  font-size: 12px;
  background: rgba(28, 41, 95);
  border-radius: 15px 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: white;
  h1 {
    text-align: center;
  }
}
.hide {
  display: none;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.close-pop-up {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}
.login-redirect {
  display: block;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}
.restart-button {
  margin: 8px 0px 0px 8px;
  padding: 4px 8px;
  background: $button;
  color: $cream;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  &:hover {
    background: lighten($button, 15%);
    color: white;
  }
}
