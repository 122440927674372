.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contact-container {
  background-color: rgba(28, 41, 95);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.contact-container h1 {
  margin-bottom: 20px;
  text-align: center;
  color: white;
}

.input-text {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: #ad1d2b; /* Updated color */
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.contact-button {
  background-color: #1b1b1b; /* Updated color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #66ffff; /* Updated color */
}
