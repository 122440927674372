@import '../../components/utils/breakpoints.scss';

.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 200px); // Account for header/footer
  padding: 80px 20px; // Top padding for header
  margin: 0 auto;
  max-width: 1200px;
  color: white;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 200px); // Account for header/footer
  padding: 80px 20px; // Top padding for header
  margin: 0 auto;
  max-width: 1200px;
  color: white;
}

.privacy-content {
  background: rgba(27, 27, 27, 0.8); // Semi-transparent background
  border-radius: 20px;
  padding: 40px;
  width: 80%;
  max-width: 900px;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  @include medium-small {
    width: 90%;
    padding: 30px;
  }

  @include small {
    width: 95%;
    padding: 20px;
  }
  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #008b8b;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(0, 139, 139, 0.3);

    @include small {
      font-size: 2em;
    }
  }
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @include small {
    font-size: 2em;
  }
}

.privacy-content h2 {
  color: #008b8b;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.5em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.privacy-content p {
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
  font-size: 1em;

  @include small {
    font-size: 0.9em;
    line-height: 1.6;
  }
}

.privacy-content strong {
  color: #008b8b;
  font-weight: 600;
}

.privacy-content em {
  display: block;
  margin-top: 10px;
  color: #ccc;
  font-style: italic;
  padding-left: 20px;
  border-left: 3px solid #008b8b;
}
