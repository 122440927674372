@import '../../components/utils/breakpoints.scss';

$cream: #eee;
$blue: darken(dodgerblue, 15%);
$button: darken(skyblue, 45%);

.course {
  &__landing-page {
    height: 100vh;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 230px;
  }
}

.no-available-course {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
  color: #fff;
  background-color: #2a475e;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 50px;
  border-radius: 20px;
  h2 {
    font-size: 40px;
  }
}
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  65% {
    -webkit-transform: translateY(-4%);
  }
  80% {
    -webkit-transform: translateY(4%);
  }
  95% {
    -webkit-transform: translateY(-2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
