.contact-messages__container {
  max-width: 1000px;
  min-width: 600px;
  margin: 150px auto 0;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.contact-messages__title {
  text-align: center;
  margin-bottom: 20px;
}

.contact-messages__title__text {
  font-size: 24px;
  color: #333;
}

/* Antetul coloanelor */
.contact-messages__header {
  display: flex;
}

.contact-messages__header span {
  flex: 1;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Lista de mesaje de contact */
.contact-messages__data-container {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.contact-messages__data-item {
  display: flex;
  width: 100%;
}

.contact-messages__data-item span {
  flex: 1;
  color: #333;
  text-align: center;
}

.contact-messages__data-buttons {
  flex: 1;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.contact-messages__data-buttons__view {
  cursor: pointer;
  font-size: 18px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-messages__data-buttons__view:hover {
  background-color: #45a049;
}

/* Mesaj de eroare */
.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Paginare */
.contact-messages__buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contact-messages__buttons-container__button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.contact-messages__buttons-container__button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.pagination-info {
  color: #333;
}
