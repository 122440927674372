.validate-container {
  display: flex;
  position: relative;
  top: 80px;
  flex-direction: column;
  margin: 100px auto;
  padding: 50px;
  max-width: 500px;
  min-width: 300px;
  background: rgba(28, 41, 95);
  border-radius: 15px 50px;
  @media (max-width: 768px) {
    max-width: 300px;
    min-width: 250px;
    padding: 20px;
  }
}

.validate-title {
  color: white;
  line-height: 1.2;
  text-align: center;
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}
.validate-text {
  color: white;
  line-height: 1.2;
  text-align: center;
  font-size: 28px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}
