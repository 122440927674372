@import "../../components/utils/breakpoints.scss";

.footer {
  background-color: #1b1b1b;
  color: white;
  padding: 20px 0;
  text-align: center;

  .footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    a {
      color: white;
      text-decoration: none;
      margin: 0 10px;
      font-size: 14px;

      &:hover {
        color: cyan;
      }
    }

    i {
      font-size: 20px;
    }

    @include small {
      flex-direction: column;
      a {
        margin: 5px 0;
      }
    }
  }

  .footer-text {
    font-size: 12px;
    p {
      text-align: center;
    }
  }
}
.flip-hint {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.7;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}