@import "../../components/utils/breakpoints.scss";


.home-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px; /* Adjust as needed to prevent footer overlap */
}
.home-container {
  margin: 20px 50px;
  border-radius: 20px;
}

.home-text {
  color: white;
  text-indent: 50px;
  line-height: 1.5;
  padding: 15px 40px 0 40px;
  text-align: justify;

  &__last {
    padding-bottom: 15px;
  }
}

@include medium-large {
  .home-container {
    margin: 100px 150px;
  }
}

@include medium {
  .home-container {
    margin: 100px 100px;
  }
}

@include medium-small {
  .home-container {
    margin: 40px 40px;
  }
  .home-text {
    font-size: 14px;
    line-height: 20px;
    text-indent: 30px;
    padding: 5px 20px 0 20px;
    flex-grow: 1;
  }
}

@include small {
  .home-container {
    margin: 40px 20px;
  }
  .home-text {
    font-size: 12px;
    text-indent: 20px;
    line-height: 16px;
    padding: 10px 20px 0 20px;
    flex-grow: 1;
  }
}

.slider {
  margin-bottom: 50px;
}

.homepage-title {
  display: flex;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 20px;
}

.homepage-title .inner {
	width: 400px;
	height: 200px;
	line-height: 200px;
	font-size: 64px;
	font-family: sans-serif;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
  @include medium {
    width: calc(400px * 0.75);
    height: calc(200px * 0.75);
    line-height: calc(200px * 0.75);
    font-size: calc(64px * 0.75);
  }

  @include medium-small {
    width: calc(400px * 0.60);
    height: calc(200px * 0.60);
    line-height: calc(200px * 0.60);
    font-size: calc(64px * 0.60);
  }

  @include small {
    width: calc(400px * 0.40);
    height: calc(200px * 0.40);
    line-height: calc(200px * 0.40);
    font-size: calc(64px * 0.40);
  }

  @include extra-small {
    width: calc(400px * 0.30);
    height: calc(200px * 0.30);
    line-height: calc(200px * 0.30);
    font-size: calc(64px * 0.30);
  }
}

.homepage-title .inner:first-child {
	background-color:#2A475E;
	color:cyan;
	transform-origin: right;
	transform: perspective(100px) rotateY(-15deg);
  box-shadow: -5px 0 5px -5px #fff,
  0 5px 5px -5px #fff,
  0 -5px 5px -5px #fff;
}

.homepage-title .inner:last-child {
	background-color: #1b1b1b;
	color: antiquewhite;
	transform-origin: left;
	transform: perspective(100px) rotateY(15deg);
  box-shadow: 0 -10px 10px -10px #fff,
              0 10px 10px -10px #fff,
              10px 0 10px -10px #fff;
}

.homepage-title .inner span {
	position: absolute;
	animation: marquee 5s linear infinite;
}

.homepage-title .inner:first-child span {
	animation-delay: 2.5s;
	left: -100%;
}

@keyframes marquee {
	from {
		left: 100%;
	}

	to {
		left: -100%;
	}
}