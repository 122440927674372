.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* This allows it to cover the full content height */
  overflow: hidden;
  pointer-events: none;
  filter: drop-shadow(0 0 10px white);
  z-index: -1;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@mixin animation-styles($total) {
  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001 + 0.5;
    $fall-duration: random_range(5, 15) * 1s;
    $fall-delay: random(200) * -0.05s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001 + 0.5;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}

.gg-music,
.gg-music-note {
  @include animation-styles(50);
  font-size: 20px;
}

.gg-music-note {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: white;

  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid white;
  }

  &::before {
    bottom: -4px;
    width: 8px;
    height: 8px;
    left: -6px;
    border-radius: 10px;
  }

  &::after {
    width: 10px;
    height: 6px;
    top: -4px;
    left: 0;
    border-radius: 2px;
    transform: skewY(-15deg);
  }
}

.gg-music {
  height: 65px;
  width: 60px;
  border-top: 15px solid #f0f8ff;
  border-right: 10px solid #f0f8ff;
  border-left: 10px solid #f0f8ff;
  margin: 30px auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 25px;
    width: 30px;
    border-radius: 50%;
    background-color: #f0f8ff;
    position: absolute;
  }

  &::before {
    bottom: -15px;
    left: -30px;
  }

  &::after {
    bottom: -10px;
    right: -10px;
  }
}
