.dashboard {
    height: 100vh;
}

.title-container {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: white;
    margin-top: 30px;
    line-height: 1.8;
    font-weight: bold;
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1;
}

.show-dropdown {
    display: grid;
}

.hide-dropdown {
    display: none;
}
.test-list {
    &__container {
    max-width:900px;
    min-width:600px;
    max-height: 100%;
    margin: auto;
    margin-top: 150px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    &__title {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &__text {
            color: black;
            margin-top: 30px;
            line-height: 1.8;
            font-weight: bold;
        }
    }
    &__data-container {
        display: flex;
        margin: 0 60px;
        justify-content: center;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
        color: #424242;
    }
    &__data-item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 500px ;

    }
    &__data-buttons {
        margin-left: 40px;
        &__edit {
            padding: 10px;
            color: #28a745;
            cursor: pointer;
            border-radius: 20px;
            &:hover {
                background-color: #ffc107;
                box-shadow: 0 0 25px #28a745;
            }
        }
      
        &__delete {
            color: #ad1d2b;
            padding: 10px;
            cursor: pointer;
            border-radius: 20px;
            &:hover {
                background-color: #59a5f6;
                box-shadow: 0 0 25px #ad1d2b;
            }
        }
    }
    &__buttons-container {
        display: flex;
        justify-content: center;
        padding:  10px 10px 10px  0;
        &__button {
            width: 120px;
            margin-top: 1rem;
            display: block;
            padding: 10px;
            border: none;
            border-radius: 10px;
            background-color: #1b1b1b;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            margin: 8px 0px 0px 8px;

            &:hover {
                color: #66ffff;
                box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
            }
        }
    }
}
.category {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 44px;
    }
    &__label {
        color: black;
    }
}
