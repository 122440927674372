@import '../../components/utils/breakpoints.scss';

.about-us-wrapper {
  display: flex;
  margin: 0;
  padding: 0;
  height: 650px;
  width: 100%;
  background-image: url('../../sources/background-image-black.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include medium-large {
    height: 570px;
  }
  @include medium {
    height: 520px;
  }
  @include medium-small {
    height: 600px;
  }
  @include small {
    height: 465px;
  }
}
.about-us-container {
  width: 650px;
  height: 450px;
  margin: 100px auto;
  position: relative;
  top: 80px;
  overflow: hidden;
  border-radius: 10px;
  @include medium-large {
    width: 520px;
    height: 370px;
    top: 60px;
  }
  @include medium {
    width: 520px;
    height: 370px;
    top: 30px;
  }
  @include medium-small {
    width: 540px;
    height: 380px;
    top: 40px;
  }
  @include small {
    width: 100%;
    height: 280px;
    top: 40px;
  }
}
.about-us-content-description {
  width: 650px;
  height: 450px;
  background: #2a475e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1.2s;
  position: relative;
  @include medium-large {
    width: 520px;
    height: 370px;
  }
  @include medium {
    width: 520px;
    height: 370px;
  }
  @include medium-small {
    width: 540px;
    height: 380px;
  }
  @include small {
    width: 100%;
    height: 280px;
  }
}

.about-us-image {
  width: 650px;
  height: 450px;
  background-image: url('../../sources/echipa.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(100%);
  transition: transform 1.2s;
  position: absolute;
  top: 0;
  left: 0;
  @include medium-large {
    width: 520px;
    height: 370px;
  }
  @include medium {
    width: 520px;
    height: 370px;
  }
  @include medium-small {
    width: 540px;
    height: 380px;
  }
  @include small {
    width: 100%;
    height: 280px;
  }
}
.about-us-container:hover .about-us-content {
  transform: translateX(-100%);
}

.about-us-container:hover .about-us-image {
  transform: translateX(0);
}
.about-us-container:hover .about-us-title,
.about-us-container:hover .about-us-description {
  display: none;
}
.about-us-title {
  font-size: 24px;
  line-height: 36px;
  color: white;
  line-height: 1.5;
  text-align: center;
  padding: 30px 0 10px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  @include medium-large {
    font-size: 22px;
    line-height: 28px;
  }
  @include medium {
    font-size: 22px;
    line-height: 28px;
  }
  @include medium-small {
    font-size: 22px;
    line-height: 22px;
    padding: 10px 0 20px 0;
  }
  @include small {
    font-size: 16px;
    line-height: 18px;
    padding: 5px 0 10px 0;
  }
}
.about-us-description {
  font-size: 18px;
  line-height: 27px;
  text-indent: 50px;
  color: white;
  text-align: center;
  padding: 0 40px 20px 40px;
  text-align: justify;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  @include medium-large {
    font-size: 16px;
    line-height: 23px;
    text-indent: 40px;
  }
  @include medium {
    font-size: 16px;
    line-height: 23px;
    text-indent: 40px;
  }
  @include medium-small {
    font-size: 16px;
    line-height: 18px;
    text-indent: 20px;
  }
  @include small {
    font-size: 13px;
    line-height: 13px;
    text-indent: 20px;
    padding: 0 10px 5px 10px;
  }
}

.first-team-member {
  background-image: url('../../sources/first-team-cover.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

  &__text {
    font-size: 16px;
    line-height: 24px;
    @include medium-large {
      font-size: 15px;
      line-height: 23px;
    }
    @include medium {
      font-size: 14px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 13px;
      line-height: 21px;
    }
    @include small {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__image {
    float: left;
    height: 800px;
    margin-right: 50px;
    border-radius: 20px;
    @include medium-large {
      height: 700px;
      margin-right: 40px;
    }
    @include medium {
      height: 600px;
      margin-right: 30px;
    }
    @include medium-small {
      height: 500px;
      margin-right: 30px;
    }
    @include small {
      height: 230px;
      margin-right: 10px;
    }
  }
  &__text-wrapper {
    margin: 70px 70px 70px 70px;
    color: white;
    text-indent: 50px;
    line-height: 1.5;
    text-align: justify;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    @include medium-small {
      margin: 50px 30px 70px 30px;
    }
    @include small {
      margin: 30px 10px 70px 10px;
    }
  }
  &__list {
    padding-top: 15px;
    @include medium-large {
      font-size: 14px;
      line-height: 23px;
    }
    @include medium {
      font-size: 13px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 12px;
      line-height: 21px;
    }
    @include small {
      font-size: 11px;
      line-height: 20px;
    }
    &__item {
      text-indent: 20px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    &__item::before {
      content: '•';
      color: white;
      margin-top: 2px;
    }
  }
}
.second-team-member {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url('../../sources/second-team-cover.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &__text {
    font-size: 16px;
    line-height: 24px;
    @include medium-large {
      font-size: 15px;
      line-height: 23px;
    }
    @include medium {
      font-size: 14px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 13px;
      line-height: 21px;
    }
    @include small {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__image {
    float: right;
    height: 800px;
    margin-left: 50px;
    border-radius: 20px;
    @include medium-large {
      height: 700px;
      margin-left: 40px;
    }
    @include medium {
      height: 600px;
      margin-left: 30px;
    }
    @include medium-small {
      height: 500px;
      margin-left: 30px;
    }
    @include small {
      height: 230px;
      margin-left: 10px;
    }
  }
  &__text-wrapper {
    margin: 70px 70px 70px 70px;
    color: white;
    text-indent: 50px;
    line-height: 1.5;
    text-align: justify;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    @include medium-small {
      margin: 50px 30px 70px 30px;
    }
    @include small {
      margin: 30px 10px 70px 10px;
    }
  }
}

.third-team-member {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url('../../sources/third-team-cover.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &__text {
    font-size: 16px;
    line-height: 24px;
    @include medium-large {
      font-size: 15px;
      line-height: 23px;
    }
    @include medium {
      font-size: 14px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 13px;
      line-height: 21px;
    }
    @include small {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__image {
    float: left;
    height: 800px;
    margin-right: 50px;
    border-radius: 20px;
    @include medium-large {
      height: 700px;
      margin-right: 40px;
    }
    @include medium {
      height: 600px;
      margin-right: 30px;
    }
    @include medium-small {
      height: 500px;
      margin-right: 30px;
    }
    @include small {
      height: 230px;
      margin-right: 10px;
    }
  }
  &__text-wrapper {
    margin: 70px 70px 70px 70px;
    color: white;
    text-indent: 50px;
    line-height: 1.5;
    text-align: justify;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    @include medium-small {
      margin: 50px 30px 70px 30px;
    }
    @include small {
      margin: 30px 10px 70px 10px;
    }
  }
}

.fourth-team-member {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  background-image: url('../../sources/fourth-team-cover.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &__text {
    font-size: 16px;
    line-height: 24px;
    @include medium-large {
      font-size: 15px;
      line-height: 23px;
    }
    @include medium {
      font-size: 14px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 13px;
      line-height: 21px;
    }
    @include small {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__image {
    float: right;
    height: 800px;
    margin-left: 50px;
    border-radius: 20px;
    @include medium-large {
      height: 700px;
      margin-left: 40px;
    }
    @include medium {
      height: 600px;
      margin-left: 30px;
    }
    @include medium-small {
      height: 500px;
      margin-left: 30px;
    }
    @include small {
      height: 230px;
      margin-left: 10px;
    }
  }
  &__text-wrapper {
    margin: 70px 70px 70px 70px;
    color: white;
    text-indent: 50px;
    line-height: 1.5;
    text-align: justify;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    @include medium-small {
      margin: 50px 30px 70px 30px;
    }
    @include small {
      margin: 30px 10px 70px 10px;
    }
  }
  &__list {
    padding-top: 15px;
    @include medium-large {
      font-size: 14px;
      line-height: 23px;
    }
    @include medium {
      font-size: 13px;
      line-height: 22px;
    }
    @include medium-small {
      font-size: 12px;
      line-height: 21px;
    }
    @include small {
      font-size: 11px;
      line-height: 20px;
    }
    &__item {
      text-indent: 20px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    &__item::before {
      content: '•';
      color: white;
      margin-top: 2px;
    }
  }
}
