@import '../../components/utils/breakpoints.scss';

$cream: #eee;
$blue: darken(dodgerblue, 15%);
$button: darken(skyblue, 45%);
:root {
  --glow-hue: 222deg;
  --shadow-hue: 180deg;
  --spring-easing: linear(
    0,
    0.002,
    0.01 0.9%,
    0.038 1.8%,
    0.156,
    0.312 5.8%,
    0.789 11.1%,
    1.015 14.2%,
    1.096,
    1.157,
    1.199,
    1.224 20.3%,
    1.231,
    1.231,
    1.226,
    1.214 24.6%,
    1.176 26.9%,
    1.057 32.6%,
    1.007 35.5%,
    0.984,
    0.968,
    0.956,
    0.949 42%,
    0.946 44.1%,
    0.95 46.5%,
    0.998 57.2%,
    1.007,
    1.011 63.3%,
    1.012 68.3%,
    0.998 84%,
    1
  );
  --spring-duration: infinite;
}

.courses {
  &__landing-page {
    height: 100vh;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 230px;
  }
  &__option-title {
    display: flex;
    font-weight: 600;
    line-height: 70px;
    font-size: 100px;
    text-align: center;
    font-size: 5rem;
    letter-spacing: 0.3rem;
    color: transparent;
    background-position: 0 0;
    animation: animated-text 20s linear infinite;
    -webkit-text-stroke: 1px rgba(16, 78, 104, 0.8);
    background-image: url('../../sources/title-culor.png');
    background-clip: text;

    @include medium {
      line-height: 60px;
      font-size: 50px;
    }
    @include medium-small {
      line-height: 40px;
      font-size: 45px;
    }
    @include small {
      line-height: 40px;
      font-size: 40px;
    }
    @include extra-small {
      font-weight: 500;
      line-height: 40px;
      font-size: 35px;
    }
    @keyframes animated-text {
      100% {
        background-position: 200% 0;
      }
    }
  }
  &__list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 15px;
  }
  &__buttons {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__disabled {
      background-color: #145b78;
      opacity: 60%;
      color: white;
    }
    &__active {
      background-color: #1b7ca4;
      color: white;
    }
    &__prev {
      margin-right: 20px;
    }
    &__general {
      border: 1px solid cyan;
      font-size: 18px;
      &:hover {
        border: 2px solid cyan;
        box-shadow: 0 0 5px #33ffff, 0 0 5px #66ffff;
        font-size: 23px;
        color: cyan;
        cursor: pointer;
      }
    }
  }
  &__item {
    background-color: #2a475e;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid cyan;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all var(--spring-duration) var(--spring-easing);
    &:hover {
      border: 2px solid cyan;
      box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%),
        inset 0 -10px 20px -10px hsla(var(--shadow-hue), 10%, 90%, 95%);
      scale: 1.1;
    }
    &:hover &__text {
      font-size: 34px;
      color: cyan;
      @include medium-small {
        font-size: 27px;
      }
      @include small {
        font-size: 22px;
      }
      @include extra-small {
        font-size: 20px;
      }
    }
    &__text {
      color: white;
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      text-decoration: none;
      text-shadow: -1px -1px 0 #1b1b1b, 1px -1px 0 #1b1b1b, -1px 1px 0 #1b1b1b,
        1px 1px 0 #1b1b1b;
      -webkit-text-stroke: 1px #1b1b1b;
      @include medium-large {
        font-size: 28px;
      }
      @include medium {
        font-size: 26px;
        text-shadow: -0.5px -0.5px 0 #1b1b1b, 0.5px -0.5px 0 #1b1b1b,
          -0.5px 0.5px 0 #1b1b1b, 0.5px 0.5px 0 #1b1b1b;
        -webkit-text-stroke: 0.5px #1b1b1b;
      }
      @include medium-small {
        font-size: 24px;
        text-shadow: -0.5px -0.5px 0 #1b1b1b, 0.5px -0.5px 0 #1b1b1b,
          -0.5px 0.5px 0 #1b1b1b, 0.5px 0.5px 0 #1b1b1b;
        -webkit-text-stroke: 0.5px #1b1b1b;
      }
      @include small {
        font-size: 20px;
        text-shadow: -0.3px -0.3px 0 #1b1b1b, 0.3px -0.3px 0 #1b1b1b,
          -0.3px 0.3px 0 #1b1b1b, 0.3px 0.3px 0 #1b1b1b;
        -webkit-text-stroke: 0.3px #1b1b1b;
      }
      @include extra-small {
        font-size: 18px;
        text-shadow: -0.2px -0.2px 0 #1b1b1b, 0.2px -0.2px 0 #1b1b1b,
          -0.2px 0.2px 0 #1b1b1b, 0.2px 0.2px 0 #1b1b1b;
        -webkit-text-stroke: 0.2px #1b1b1b;
      }
    }
  }
}
.no-available-courses {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
  color: #fff;
  background-color: #2a475e;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 50px;
  border-radius: 20px;
  h2 {
    font-size: 40px;
  }
}
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  65% {
    -webkit-transform: translateY(-4%);
  }
  80% {
    -webkit-transform: translateY(4%);
  }
  95% {
    -webkit-transform: translateY(-2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}

.pop-up-container {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: fixed;
  padding: 15px;
  max-width: 400px;
  font-size: 12px;
  background: rgba(28, 41, 95);
  border-radius: 15px 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: white;
  h1 {
    text-align: center;
  }
}
.hide {
  display: none;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.close-pop-up {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}
.login-redirect {
  display: block;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}
