body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-image: url("../src/sources/background-image-black.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
