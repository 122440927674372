.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.register-container {
  display: flex;
  position: relative;
  top: 80px;
  max-width: 320px;
  max-height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(28, 41, 95);
  border-radius: 15px 50px;
  padding: 20px 5% 20px 5%;
  margin: auto;
  margin-top: 100px;
}

.input-text {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 16px 18px;
  background-position: 98% 50%;
  margin-bottom: 15px;
}

.register-button {
  display: block;
  margin: 10px 0 30px 0;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .register-container {
    max-width: 250px;
    max-height: 350px;
  }
}

.error-message {
  color: #fff;
}
