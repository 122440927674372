
.dashboard-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.dashboard-item {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: opacity 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
    margin: 10px;
    border-radius: 50%;
}

.blue {
    background-color: #007bff;
}
.blue:hover {
    box-shadow: 0 0 25px #007bff;
}

.green {
    background-color: #28a745;
}
.green:hover {
    box-shadow: 0 0 25px #28a745;
}

.yellow {
    background-color: #ffc107;
}
.yellow:hover {
    box-shadow: 0 0 25px #ffc107;
}

.red {
    background-color: #dc3545;
}
.red:hover {
    box-shadow: 0 0 25px #dc3545;
}

.long-rectangle {
    width: 660px;
    height: 80px;
    background-color: #2A475E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    opacity: 0.9;
    border-radius: 20px;
}
.dashboard-text {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-shadow: -0.5px -0.5px 0 #1b1b1b , 0.5px -0.5px 0 #1b1b1b , -0.5px 0.5px 0 #1b1b1b , 0.5px 0.5px 0 #1b1b1b ;
    -webkit-text-stroke: 0.5px #1b1b1b ;
}