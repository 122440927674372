@import "../breakpoints.scss";

.book {
  position: relative;
  margin: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.loading {
    opacity: 0;
  }

  .flip-hint {
    position: absolute;
    bottom: 5%; // Use percentage instead of fixed pixels
    display: flex;
    align-items: center;
    font-size: calc(14px + 0.5vmin); // Responsive font size
    color: #ffffff;
    opacity: 0.7;
    padding: 0 2%; // Responsive padding

    svg {
      width: calc(20px + 0.5vmin); // Responsive icon size
      height: calc(20px + 0.5vmin);
      margin: 0 5px;
    }

    &.left {
      left: 2%; // Percentage-based positioning
    }

    &.right {
      right: 2%; // Percentage-based positioning
    }
  }


  .page, .cover {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    position: absolute;
    flex-direction: column;

    h1 {
      color: #fff;
      font-size: 37px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;

      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      line-height: 1.5;
      padding: 10% 5% 0 5%;
      text-align: justify;
      text-indent: 50px;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 1.4;
        text-indent: 30px;
      }
    }
  }

  .cover {
    background-color: #2a475e;
  }

  .page {
    background-color: #2a475e;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .book-pages {
    overflow: hidden;
    touch-action: none;
  }
}