.dashboard {
  height: 100vh;
}

.dashboard-home {
  position: relative;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.long-rectangle {
  width: 660px;
  height: 80px;
  background-color: #2a475e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 20px auto;
  opacity: 0.9;
  border-radius: 20px;
}

.dashboard-text {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  text-shadow: -0.5px -0.5px 0 #1b1b1b, 0.5px -0.5px 0 #1b1b1b,
    -0.5px 0.5px 0 #1b1b1b, 0.5px 0.5px 0 #1b1b1b;
}

.dashboard-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-item {
  width: 150px;
  height: 150px;
  margin: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
}

.dashboard-item:hover {
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
}

.green {
  background-color: #28a745;
}

.green:hover {
  box-shadow: 0 0 25px rgba(40, 167, 69, 0.7);
}

.yellow {
  background-color: #ffc107;
}

.yellow:hover {
  box-shadow: 0 0 25px rgba(255, 193, 7, 0.7);
}

.blue {
  background-color: #007bff;
}

.blue:hover {
  box-shadow: 0 0 25px rgba(0, 123, 255, 0.7);
}
