.edit-user__container {
  display: block;
  color: #fff;
  max-width: 600px;
  margin: 150px auto 0;
  background-color: rgba(28, 41, 95);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 5px;
}

.edit-user__container .edit-user__title {
  text-align: center;
  margin-bottom: 20px;
}

.edit-user__container .edit-user__title__text {
  font-size: 24px;
  color: #fff;
}

.edit-user__container .form-group-users {
  margin-bottom: 15px;
}

.edit-user__container .form-group-users .users-form-label {
  display: block;
  margin: 0 auto 5px; /* Centers the label horizontally */
  font-weight: bold;
  color: #fff;
}

.edit-user__container .form-group-users input,
.form-group-users select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  background-color: #fff;
  display: block;
}

.edit-user__container .form-group-users input[type='checkbox'] {
  display: block;
  width: auto;
}

.edit-user__container .edit-user__button {
  margin-top: 1rem;
  display: block;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
}

.edit-user__container .edit-user__button:hover {
  color: #66ffff;
  box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
}

.edit-user__container .edit-user__container .error-message {
  color: red;
  margin-bottom: 10px;
}
