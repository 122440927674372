

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-box {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider-box:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider-box {
    background-color: #4CAF50;
  }
  
  input:focus + .slider-box {
    box-shadow: 0 0 1px #4CAF50;
  }
  
  input:checked + .slider-box:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  .availability-toggle {
    display: flex;
    align-items: center;
  }
  
  .availability-label {
    margin-left: 10px;
    color: white;
  }

.file-info {
color: white; /* Setează culoarea textului la alb */
margin-top: 10px; /* Poți ajusta marginea de sus pentru a se potrivi design-ului tău */
}
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrare pe axa orizontală */
  }
  
  .file-buttons {
    display: flex;
    gap: 10px; /* Spațiu între butoane */
  }
  
  .question-file-upload,
  .remove-question-file-image {
    display: inline-block; /* Asigură că butoanele sunt pe aceeași linie */
  }