.admin-menu {
    background-color: #1b1b1b;
    width: 80px;
    padding: 70px 20px 20px 20px;
    transition: width 0.3s ease;
    height: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
}

.admin-menu:hover {
    width: 200px;
  }

.admin-menu-title {
    margin-bottom: 20px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f1f1f1;
  }

  .admin-menu:hover .admin-menu-title {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .admin-menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

.admin-menu-item {
    margin-bottom: 30px;
  }

.admin-menu-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.admin-menu:hover .admin-menu-text {
    opacity: 1;
}

.admin-menu-icon {
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-right: 30px;
    color: #f1f1f1;
}

.admin-menu-text {
    font-size: 16px;
    color: #f1f1f1;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 8px 15px;
}

.admin-menu-item:hover span  {
    color: #66ffff;
}
.admin-menu-item:hover .admin-menu-icon  {
    color: #66ffff;
}
.admin-menu-item:hover .admin-menu-text  {
    box-shadow: 0 0 5px #66ffff;
}

