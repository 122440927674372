$extra-small: 320px;
$small: 575px;
$medium-small: 767px;
$medium: 991px;
$medium-large: 1280px;
$large: 1440px;

:export {
  extra-small: $extra-small;
  small: $small;
  medium-small: $medium-small;
  medium: $medium;
  medium-large: $medium-large;
  large: $large;
}

@mixin extra-small {
  @media screen and (max-width: #{$extra-small + 0.9px}) {
    @content;
  }
}

@mixin small {
  @media screen and (max-width: #{$small + 0.9}) {
    @content;
  }
}

@mixin medium-all {
  @media screen and (min-width: #{$small + 1}) and (max-width: #{$medium-large + 0.9}) {
    @content;
  }
}

@mixin medium-small {
  @media screen and (min-width: #{$small + 1}) and (max-width: #{$medium-small + 0.9}) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: #{$medium-small + 1}) and (max-width: #{$medium + 0.9}) {
    @content;
  }
}

@mixin medium-large {
  @media screen and (min-width: #{$medium + 1}) and (max-width: #{$medium-large + 0.9}) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: #{$medium-large + 1}) {
    @content;
  }
}

@mixin not-small {
  @media screen and (min-width: #{$small + 1}) {
    @content;
  }
}

@mixin up-to-medium-small {
  @media screen and (min-width: #{$medium-small + 1}) {
    @content;
  }
}

@mixin not-medium-all {
  @media screen and (max-width: #{$small + 0.9}),
    screen and (min-width: #{$medium-large + 1}) {
    @content;
  }
}

@mixin not-large {
  @media screen and (max-width: #{$medium-large + 0.9}) {
    @content;
  }
}
