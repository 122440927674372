.dashboard {
  height: 100vh;
}

.title-container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: white;
  margin-top: 30px;
  line-height: 1.8;
  font-weight: bold;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
}

.show-dropdown {
  display: grid;
}

.hide-dropdown {
  display: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 2rem;

  .form-group {
    min-width: 310px;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;

    &__add-question {
      flex-direction: column;
    }

    &__category {
      margin-bottom: 44px;
    }
  }

  .btn-primary {
    margin-top: 1rem;
    display: block;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #1b1b1b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: #66ffff;
      box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
    }
  }

  .custom-title {
    display: block;
    padding: 10px;
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
  }

  .question-text {
    display: inline-block;
    width: 250px;
    max-width: 400px;
    max-height: 300px;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 16px;
    word-wrap: break-word;
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: both;
    box-sizing: border-box;
    text-align: left;
    line-height: 1.5;
    overflow-x: auto;
    overflow-y: scroll;
    white-space: normal;
    margin-right: 10px;
  }

  .answer-text {
    display: block;
    width: 250px;
    max-width: 400px;
    max-height: 300px;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 16px;
    word-wrap: break-word;
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: both;
    box-sizing: border-box;
    text-align: left;
    line-height: 1.5;
    overflow-x: auto;
    overflow-y: scroll;
    white-space: normal;
    margin-left: 10px;
  }

  .question-container {
    display: flex;
  }
}

.test-form-container {
  max-width: 1100px;
  margin: auto;
  margin-top: 100px;
  background: rgba(28, 41, 95);
  border-radius: 10px;
}

.test-form-container .form-group label {
  margin-right: 10px;
  color: white;
}

.add-question {
  width: 120px;
  margin-top: 1rem;
  display: block;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.dropdown button {
  display: block;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.add-answer {
  width: 35px;
  height: 35px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  background-color: #1b1b1b;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.delete-answer {
  width: 35px;
  height: 35px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  background-color: #1b1b1b;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.answer-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.answer-checkbox {
  display: block;
  order: 1;
  margin-left: 10px;
}
.question-file-upload {
  display: flex;
  width: 120px;
  height: 38px;
  align-items: center;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    color: #66ffff;
    box-shadow: 0 0 5px #66ffff, 0 0 5px #66ffff;
  }
}

.upload-file-text {
  margin-right: 0.5rem;
}

.uploaded-image {
  display: block;
  max-width: 400px;
  max-height: 300px;
  margin-top: 10px;
}
.order {
  display: block;
  padding: 10px;
  width: 70px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 16px 18px;
  background-position: 98% 50%;
}
.error {
  background-color: red !important;
}

#fileInput {
  display: none;
}
