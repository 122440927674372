.view-contact-message__container {
  max-width: 800px;
  margin: 150px auto 0;
  padding: 20px;
  background-color: #fff;
}

.view-contact-message__title {
  text-align: center;
}

.view-contact-message__details {
  margin-top: 20px;
  text-align: left;
}

.view-contact-message__details p {
  margin-bottom: 10px;
  font-size: 16px;
}

.message-content {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}
