@import '../../components/utils/breakpoints.scss';

$cream: #eee;
$blue: darken(dodgerblue, 15%);
$button: darken(skyblue, 45%);

.quiz-name {
  color: white;
  font-size: 30px;
  text-align: center;
  margin: 20px 30px 0px 30px;
  text-shadow: -1px -1px 0 #1b1b1b, 1px -1px 0 #1b1b1b, -1px 1px 0 #1b1b1b,
    1px 1px 0 #1b1b1b;
  -webkit-text-stroke: 1px #1b1b1b;

  @include medium-large {
    font-size: 28px;
  }
  @include medium {
    font-size: 26px;
    text-shadow: -0.5px -0.5px 0 #1b1b1b, 0.5px -0.5px 0 #1b1b1b,
      -0.5px 0.5px 0 #1b1b1b, 0.5px 0.5px 0 #1b1b1b;
    -webkit-text-stroke: 0.5px #1b1b1b;
  }
  @include medium-small {
    font-size: 24px;
    text-shadow: -0.5px -0.5px 0 #1b1b1b, 0.5px -0.5px 0 #1b1b1b,
      -0.5px 0.5px 0 #1b1b1b, 0.5px 0.5px 0 #1b1b1b;
    -webkit-text-stroke: 0.5px #1b1b1b;
  }
  @include small {
    margin: 20px 15px 0px 15px;
    font-size: 20px;
    text-shadow: -0.3px -0.3px 0 #1b1b1b, 0.3px -0.3px 0 #1b1b1b,
      -0.3px 0.3px 0 #1b1b1b, 0.3px 0.3px 0 #1b1b1b;
    -webkit-text-stroke: 0.3px #1b1b1b;
  }
  @include extra-small {
    margin: 20px 15px 0px 15px;
    font-size: 18px;
    text-shadow: -0.2px -0.2px 0 #1b1b1b, 0.2px -0.2px 0 #1b1b1b,
      -0.2px 0.2px 0 #1b1b1b, 0.2px 0.2px 0 #1b1b1b;
    -webkit-text-stroke: 0.2px #1b1b1b;
  }
}
.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 300px 100px 300px;
  height: 100vh;

  @include medium-large {
    margin: 0 200px 80px 200px;
  }
  @include medium {
    margin: 0 100px 60px 100px;
  }
  @include medium-small {
    margin: 0 50px 40px 50px;
  }
  @include small {
    margin: 0 30px 20px 30px;
  }
  @include extra-small {
    margin: 0 20px 10px 20px;
  }
}
.question {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #2a475e;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  &__text {
    text-align: center;
    color: #fff;
    padding: 20px 20px 0px 20px;
    margin: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    @include medium {
      font-size: 22px;
      line-height: 24px;
    }
    @include medium-small {
      font-size: 20px;
      line-height: 24px;
    }
    @include small {
      font-size: 16px;
      line-height: 22px;
      padding: 20px 10px 0px 10px;
      margin: 10px 5px 10px 5px;
    }
    @include extra-small {
      font-size: 15px;
      line-height: 20px;
      padding: 20px 10px 0px 10px;
      margin: 10px 5px 10px 5px;
    }
  }
  &__image {
    max-width: 500px;
    max-height: 250px;
    margin: 0 auto;
  }
}
.quiz-answer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px;
  margin: 10px 10px;
  @include medium-large {
    padding: 20px 30px;
    margin: 10px 20px;
  }
  @include medium {
    padding: 30px 30px;
    margin: 15px 15px;
  }
  @include medium-small {
    padding: 30px 25px;
    margin: 15px 20px;
  }
  @include small {
    padding: 30px 10px;
    margin: 15px 10px;
  }
  @include extra-small {
    padding: 30px 5px;
    margin: 15px 5px;
  }
}
.answer-first-row,
.answer-second-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
}
.answer {
  flex: 1;
  box-sizing: border-box;
  color: white;
  background: $button;
  margin: 5px;
  padding: 10px;
  border: 1px solid $button;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  &__label {
    font-size: 16px;
    padding: 10px;
    word-wrap: break-word;
    @include small {
      font-size: 14px;
      padding: 1px;
    }
    @include extra-small {
      font-size: 12px;
      padding: 1px;
    }
  }
  &__input {
    display: none;
  }
}
.answer:hover {
  background: lighten($button, 15%);
}
.answer:has(.answer__input:checked) {
  background: $blue;
  box-shadow: 0 0 10px #33ffff, 0 0 10px #66ffff;
}

.quiz {
  &__buttons-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  &__button {
    margin: 8px 0px 0px 8px;
    padding: 4px 8px;
    background: $button;
    color: $cream;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    @include small {
      margin: 4px 0px 0px 4px;
      padding: 2px 4px;
      margin-right: 5px;
      margin-left: 5px;
    }
    @include extra-small {
      margin: 4px 0px 0px 4px;
      padding: 2px 4px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &__button:hover {
    background: lighten($button, 15%);
    color: white;
  }
  &__button:disabled {
    opacity: 0.8;
    background: lighten($button, 10%);
    cursor: default;
  }
}

.results-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  color: #fff;
  background-color: #2a475e;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 50px;
  border-radius: 20px;
  h2 {
    font-size: 40px;
  }
  p {
    font-size: 25px;
    padding-bottom: 10px;
  }
}

.question.hidden {
  top: -100%;
  opacity: 0;
}

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  65% {
    -webkit-transform: translateY(-4%);
  }
  80% {
    -webkit-transform: translateY(4%);
  }
  95% {
    -webkit-transform: translateY(-2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
