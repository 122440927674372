@import "../../components/utils/breakpoints.scss";

*{
    margin: 0;
    box-sizing: border-box;
    font-family: Georgia, serif;
}

body{
    background: #f2f2f2;
}

nav{
    background: #1b1b1b;
    z-index: 999;
}

nav::after{
    content: '';
    clear: both;
    display: table;
}
nav .logo{
    float: left;
}
nav ul{
    float: right;
    list-style: none;
    position: relative;
    padding-inline-start: 0px;

    @include large {
        margin-right: 65px;
    }
    @include medium-large {
        margin-right: 60px;
    }
}
nav ul li{
    display: inline-block;
    background: #1b1b1b;
    white-space: nowrap;
    @media all and (min-width: 992px) {
        margin: 0 5px;
    }
    
}
nav ul li a{
    color: white;
    text-decoration: none;
    line-height: 80px;
    padding: 8px 15px;
    @media (max-width: 991px) {
        line-height: 70px;
    }
}
nav ul li a:hover{
    color: cyan;
    border-radius: 5px;
    box-shadow: 0 0 5px #33ffff,
                0 0 5px #66ffff;
}
nav ul ul li a:hover{
    color: cyan;
    box-shadow: none;
}
nav ul ul{
    position: absolute;
    top: 90px;
    border-top: 3px solid cyan;
    opacity: 0;
    visibility: hidden;
    transition:top 0.3s;
}
nav ul ul ul{
    border: none;
}
nav ul li:hover>ul{
    top: 80px;
    opacity: 1;
    visibility: visible;
    @media all and (min-width: 992px) {
        display: block;
    }
}
nav ul ul li{
    position: relative;
    margin: 0px;
    float: none;
    display: list-item;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    
}
nav ul ul li a{
    line-height: 50px;
}
nav ul ul ul li{
    position: relative;
    top: -80px;
    left: 150px;
    width: 250px;
}
.show,.icon,input{
    display: none;
}
@media all and (min-width: 992px) {
    .lists li {
        width: 100%;
    }
}
@media all and (max-width: 991px){
    nav ul {
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        height: 70%;
        width: 85%;
        background-color: #1b1b1b;
      }
    nav ul li{
        display: block;
        width: 100%;
    }
    nav ul ul{
        top: 80px;
        position: static;
        border-top: none;
        float: none;
        display: none;
        opacity: 1;
        visibility: visible;
    }
    nav ul ul ul li{
        position: static;
    }
    nav ul ul li{
        border-bottom: 0px;
    }

    nav ul ul ul a{
        padding-left:80px ;
    }
    .show{
        display: block;
        color: white;
        font-size: 18px;
        padding: 0 20px;
        line-height: 80px;
        cursor: pointer;
        @media (max-width: 991px) {
            line-height: 70px;
        }
    }
    .show:hover{
        color: cyan;
    }
    .icon{
        display: block;
        color: white;
        position: absolute;
        right: 20px;
        line-height: 80px;
        font-size: 25px;
        cursor: pointer;
    }
    nav ul li a:hover{
        font-size: 14px;
        box-shadow: none;
    }
    .show + a, ul{
        display: none;
    }
    [id^=btn]:checked + ul{
        text-align: center;
        display: inline;
    }

}
.lists {
    padding: 0px;
}

.onoffswitch {
    position: relative;
    width: 30px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 15px;
    width: 30px;
    padding: 0;
    line-height: 15px;
    border: 1px solid #EEEEEE;
    border-radius: 15px;
    background-color: #EEEEEE;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block;
    width: 15px;
    margin: 0px;
    background: #73233C;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 13px;
    border: 2px solid #EEEEEE;
    border-radius: 15px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: white;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: white;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}
.switch-container {
  display:inline-flex;
  align-items: center;
  padding: 8px;
}
.option {
    color:white;
    padding: 2px;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
.header-wrap {
    padding-top: 80px;
    margin-bottom: -100px;
}
.menu-active {
    height: 70%;
    transform: translateX(0);
}

.lists {
    display: none;
  }

  @media (max-width: 991px) {
    .lists .show-inline {
      display: inline;
    }
  }

  @media (min-width: 992px) {
    .lists .show-block {
      display: block;
    }
  }